<template>
  <div class="navbar-roll">
    <div>
      <b-img
        v-if="checkOutHome()"
        fluid
        src="@/assets/images/logo/ooh.avif"
        alt="Logo"
        class="logo-brandme"
      />
      <b-img
        v-else
        src="@/assets/images/logo/logo-brandme.png"
        alt="Logo"
        class="logo-brandme"
      />
    </div>

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
      id="dropdown-user-menu-purple"
      menu-class="size-submenu"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0" style="color: white !important">
            {{user_data ? user_data.first_name || user_data.first_name : ""}}
          </p>
        </div>
        <b-avatar
          v-if="user_data"
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon v-if="user_data && user_data.profile_image === null" icon="UserIcon" size="22" class="text-white" />
        </b-avatar>
      </template>
      <b-dropdown-item>
        <div class="d-flex flex-column">
          <span class=""> {{user_data ? user_data.first_name || user_data.first_name : ""}} {{user_data ? user_data.last_name || user_data.last_name : "" }}</span>
          <span class="avenir-medium"> {{user_data.email}}</span>
        </div>
      </b-dropdown-item>
      <b-dropdown-divider class="px-1"/>
      <b-dropdown-item link-class="d-flex align-items-center menu-dropdown" @click="logoutFunction()">
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
          variant="light-primary"
        />
        <span>{{ $t("header.userMenu.logout") }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>
  
<script>
import { 
  BImg,
  BNavItemDropdown,
  BDropdownDivider,
  BDropdownItem,
  BAvatar,
} from 'bootstrap-vue';
import { getUserData, logout } from '@/libs/utils/user';
import { initialAbility } from "@/libs/acl/config";

export default {
  data() {
    return {
      user_data: getUserData(),
      logout,
    }
  },
  components: {
    BImg,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAvatar,
  },
  methods: {
    checkOutHome() {
      return window.location.host.includes('outofhome')
    },
    logoutFunction() {
      // Reset ability
      this.$ability.update(initialAbility);
      logout();
    }
  }
}


</script>
<style lang="scss">
.navbar-roll {
  height: 70px;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(120deg, rgb(105, 71, 188), rgb(153, 62, 144)) !important;
  list-style: none;
  align-items: center;
  padding: 0 1em 0 5em;

  @media(max-width: 1200px) {
    padding: 0 1em;
  }

  .logo-brandme {
    width: 100px;
  }
}
</style>